import jquery from 'jquery';
import 'jquery.scrollto';
import 'bootstrap/js/dist/scrollspy';


function scrollToLinkTarget(linkNode: HTMLElement, navbarNode: JQuery) {
    const scrollOffset = navbarNode.css('position') == 'fixed' ?
        navbarNode.outerHeight() : 0;

    let location = jquery(linkNode).attr('href');//.substr(1);

    if (location === "#") {
        location = 'body'
    }
    jquery.scrollTo(location, 750, {offset: scrollOffset * -1});
    if (history.pushState) {
        history.pushState(null, null, jquery(this).attr('href'));
    }
}

jquery(() => {
    const navbarNode = jquery('.navbar');
    jquery('body').scrollspy({offset: navbarNode.outerHeight() + 5});

    jquery('a[href^="#"]', navbarNode).on('click', function () {
        const collapseNode = jquery(this).closest('.collapse.show');

        if (collapseNode.length) {
            collapseNode.one('hidden.bs.collapse', () => scrollToLinkTarget(this, navbarNode));
            collapseNode.collapse('hide');
        } else {
            scrollToLinkTarget(this, navbarNode);
        }

        return false;
    });
});
